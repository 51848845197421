<template>
  <div id="service-screen" translate="no">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :height="40"
      loader="spinner"
      color="#003340"
    ></Loading>
    <WhiteHeader
      v-if="store"
      :showNavigation="false"
      :bannerImage="store.bannerLink"
      :storeImage="store.logoLink"
      :isApplication="isApplication"
    ></WhiteHeader>
    <LanguageSelect
      v-if="isAbleToChangeLanguage"
      id="language-select"
      :class="{ 'language-position-in-login': isLoggedIn}"
    ></LanguageSelect>
    <ProfileDropdown
      v-if="isLoggedIn && tableType!='SELF_SERVICE_POS'"
      id="profile-dropdown"
      :isApplication="isApplication"
      :class="{ 'profile-dropdown-without-lang-select': !isAbleToChangeLanguage }"
    ></ProfileDropdown>
    <EnterPrompt
      v-else-if="!isLoggedIn && shouldShowEnterPrompt"
      @enter-clicked="initiateEntrance"
      id="enter-prompt"
      :class="{ 'enter-prompt-without-lang-select': !isAbleToChangeLanguage }"
    ></EnterPrompt>
    <div id="store-info">
      <Store id="store" :store="store" :shouldShowMenu="false"></Store>
    </div>
    <div
      id="mask"
      @click="onClickOutside"
      :class="{
        mask: isEntranceOpen || isLanguageModalOpen,
      }"
    ></div>
    <div id="body">
      <div
        id="services-container"
        style="width: 100%; height: 100%"
        v-if="(services && services.length > 1) || (services && services.length == 1 && !services[0].restaurant.goToMenuFromServicesScreen)"
      >
        <Service
          v-for="service in services"
          :key="service.restaurant.id"
          :service="service"
          :language="language"
          @check-menu="checkMenu"
          @go-to-open-tables="goToOpenTables"
          @go-to-open-rooms="goToOpenRooms"
        ></Service>
      </div>
    </div>
    <Footer v-if="store && store.showFluterFooter"></Footer>
    <PoweredByFooter v-else></PoweredByFooter>
  </div>
</template>

<script>
import WhiteHeader from "../WhiteHeader.vue";
import Service from "../Service.vue";
import ProfileDropdown from "../ProfileDropdown.vue";
import LanguageSelect from "../LanguageSelect";
import EnterPrompt from "../EnterPrompt.vue";
import Footer from "../Footer.vue";
import PoweredByFooter from "../PoweredByFooter.vue";
import Store from "../Store.vue";
import EntranceModal from "../EntranceModal.vue";

import MenuApi from "../../api/menu";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    WhiteHeader,
    Service,
    ProfileDropdown,
    LanguageSelect,
    EnterPrompt,
    Footer,
    PoweredByFooter,
    Store,
    EntranceModal,
    Loading,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEntranceOpen) {
      this.closeEntrance();
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },
  async created() {
    this.isLoading = true;
    window.scrollTo(0, 0);
    let tableId = this.$router.currentRoute.params.tableId;
    let result = this.$store.getters.getStoreWithAllServices == null ? await MenuApi.getServices(tableId) : this.$store.getters.getStoreWithAllServices;

    if (!this.from || !this.from.name || !this.from.name.includes("menu")) {
      this.increaseCounter = true;
    } else {
      this.increaseCounter = false;
    }

    this.$store.dispatch("setStoreId", result.store.id);

    if (result.menus.length == 1 && result.menus[0].restaurant.goToMenuFromServicesScreen) {
      let restaurant = result.menus[0].restaurant;

      let redirectLinkBasedOnLanguage = restaurant.names.translation[this.language].redirectLink;
      if (redirectLinkBasedOnLanguage != null && redirectLinkBasedOnLanguage.trim().length > 0) {
        window.location.href = redirectLinkBasedOnLanguage;
      } else if (restaurant.redirectLink) {
        window.location.href = restaurant.redirectLink;
      } else {
        this.store = result.store;
        this.$router.push({
          name: "menu",
          params: {
            tableId: tableId,
            service: restaurant.routeName,
            increaseCounter: this.increaseCounter,
            menu: result,
          },
        });
      }

    } else {
      this.$store.dispatch("setStoreWithAllServices", result);
      this.$store.dispatch("setTableId", tableId);
      this.services = result.menus;
      this.tableType = result.menus[0].table.type;
      this.isApplication = result.menus[0].table.application;
      this.store = result.store;

      // we show enterprompt only for non read_only stores
      // in order to avoid unecassary logins and not to get charged
      this.shouldShowEnterPrompt = this.store.pricingPackage != "READ_ONLY";

      this.isAbleToChangeLanguage = result.store.ableToChangeLanguage;
      if (result.store.ableToChangeLanguage) {
        try {
          let navigatorLanguage = navigator.language;
          if (navigatorLanguage == 'el') {
            this.$store.dispatch("setDefaultLanguage", 'GRE');
          } else {
            this.$store.dispatch("setDefaultLanguage", result.store.language);
          }
        } catch (error) {
          this.$store.dispatch("setDefaultLanguage", result.store.language);
        }
      } else {
        this.$store.dispatch("setLanguageOnlyToMemory", result.store.language);
      }

      this.isLoading = false;
    }
  },

  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      services: undefined,
      store: undefined,
      isLoading: false,
      increaseCounter: undefined,
      from: undefined,
      shouldShowEnterPrompt: false,
      tableType: undefined,
      isApplication: false,
      isAbleToChangeLanguage: true
    };
  },
  methods: {
    onClickOutside: function (event) {
      if (this.isEntranceOpen && event.target.id == "mask") {
        this.closeEntrance();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      }
    },

    initiateEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceIn");
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");
      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    checkMenu: function (id) {
      this.isLoading = true;

      let tableId = this.$router.currentRoute.params.tableId;
      let menu = {
        store: this.store,
        menus: this.services.filter((service) => service.restaurant.id == id),
      };

      let restaurant = menu.menus[0].restaurant;

      let redirectLinkBasedOnLanguage = restaurant.names.translation[this.language].redirectLink;
      if (redirectLinkBasedOnLanguage != null && redirectLinkBasedOnLanguage.trim().length > 0) {
        window.location.href = redirectLinkBasedOnLanguage;
      } else if (restaurant.redirectLink) {
        window.location.href = restaurant.redirectLink;
      } else {
        this.$router.push({
          name: "menu",
          params: {
            tableId: tableId,
            service: restaurant.routeName,
            increaseCounter: this.increaseCounter,
            menu: menu,
          },
        });

        this.isLoading = false;
      }

    },

    goToOpenTables() {
      let tableId = this.$router.currentRoute.params.tableId;
      this.isLoading = true;
      this.$router.push({
        name: "open-tables",
        params: {
          storeId: this.store.id,
          tableId: tableId,
          store: this.store
        },
      });
      this.isLoading = false;
    },

    goToOpenRooms() {
      let tableId = this.$router.currentRoute.params.tableId;
      this.isLoading = true;
      this.$router.push({
        name: "open-rooms",
        params: {
          storeId: this.store.id,
          tableId: tableId,
          store: this.store
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
#body {
  display: flex;
  flex-direction: column;

  /* grid-template-columns: auto;
  grid-auto-flow: row dense; */
  background: #f7f7f7;
  width: 100%;
  min-height: 60vh;
  align-items: center;
  padding: 5vh 15vw 5vh 15vw;
}
@media screen and (max-width: 1285px) {
  #body {
    padding: 5vh 5vw;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-90vw);
  }

  to {
    transform: translateX(0);
  }
}

#services-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  animation: slideFromLeft 0.23s forwards linear;
}

#profile-dropdown {
  position: absolute;
  top: 69px;
  right: 20px;
  z-index: 201;
}

.profile-dropdown-without-lang-select {
  top: 18px !important;
}

#language-select {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 202;
}

#enter-prompt {
  position: absolute;
  top: 69px;
  right: 20px;
  z-index: 201;
}

.enter-prompt-without-lang-select {
  top: 20px !important;
}

@media screen and (max-width: 1285px) {
  #profile-dropdown {
    top: 73px;
  }

  #enter-prompt {
    top: 71px;
  }

  #language-select {
    left: unset;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
}

#store-info {
  position: absolute;
  top: 20vh;
  right: 20px;
  width: 30%;
  min-width: 400px;
  background: white;
  border-radius: 10px;
  border-radius: 18px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}
@media (max-width: 1200px) {
  #store-info {
    top: 15vh;
  }
}
@media (max-width: 1285px) {
  #store-info {
    top: 165px;
    width: 90%;
    left: 5%;
    min-width: 200px;
    max-height: 112px;
  }
}

@media screen and (max-width: 350px) {
  #store-info {
    top: 140px;
  }
}
</style>